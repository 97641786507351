<script>
import ModelMixin from './ModelMixin';
import Component, { mixins } from 'vue-class-component';
import UIFormItem from './UIFormItem';
import { ValidationProvider } from 'vee-validate';

@Component({
  name: 'UICheckbox',
  inheritAttrs: false,
  props: {
    title: {},
    instructions: {},
    required: Boolean,
    disabled: Boolean,
    checkboxValue: String,
    rules: {
      type: [String, Object],
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    fieldNameOverride: {
      type: String,
    },
    darkMode: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    UIFormItem,
    ValidationProvider,
  },
})
export default class UICheckbox extends mixins(ModelMixin) {

}
</script>

<template>
  <ValidationProvider tag="div" :rules="rules" v-slot="{ errors }" :vid="vid" :name="!fieldNameOverride ? title : fieldNameOverride">
    <div class="ui-checkbox" :title="title" :instructions="instructions" :required="required" :root="true" :disabled="disabled">
      <label class="box-and-title">
        <input class="ui-checkbox-input" v-bind="$attrs" :value="checkboxValue" type="checkbox" v-on="$listeners" v-model="internalValue" :disabled="disabled" />
        <span class="title" :class="darkMode ? 'dark' : ''">
          <slot name="title">{{ title }}</slot>
        </span>
      </label>
      <span class="instructions">{{ instructions }}</span>
      <span class="error">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<style scoped lang="postcss">
@import "../../styles";

.ui-checkbox {
  & .box-and-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    & .ui-checkbox-input {
      margin-right: var(--spacingXS);
    }

    & .title {
      @apply --f8;
    }
  }

  & .instructions {
    @apply --f7;
  }

  &[disabled] {
    & .box-and-title,
    & .ui-checkbox-input,
    & .instructions {
      cursor: not-allowed;
    }

    & .title {
      color: var(--colorManatee);
    }
  }
}
.error {
  @apply --f7;
  color: var(--colorErrorFlat);
}

.title.dark {
  color: var(--darkModeColorText) !important;
}
</style>
